import "./src/components/styles/global.css";

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   const currentPosition = getSavedScrollPosition(location);
//   window.scrollTo(...currentPosition);

//   return true;
// };
